import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Ibia = () => {
    return (
        <div className="container py-3 py-lg-4">
            <div className="row g-0 mb-3">
                <div className="col border-bottom border-1 border-dark">
                    <h1>International Bunker Industry Association</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6 mb-2 mb-md-0 ">
                <p>Delta Energy are a proud corporate member of the organization IBIA, International Bunker Industry Association. </p>
                <p>Taking part in a variety of events, conferences, engaging in working groups with a global network of bunker industry experts. </p>
                <p>Delta Energy are always increasing the professional understanding of industry methods, standards, international policy and new legislations and regulations.</p>
                </div>
                <div className="col-12 col-md-6 mb-2 mb-md-0 d-flex justify-content-center">
                   <StaticImage
                                    alt="IBIA"
                                    src="../../../images/other/ibia.png"
                                    width={350}
                                />
                </div>
            </div>
        </div>
    )
}

export default Ibia
