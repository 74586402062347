import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Services = () => {
    const layout = "fixed"
    const aspectRatio = 16 / 8
    const width = 640

    return (
        <div className="container py-3 py-lg-4">
            <div className="row g-0 mb-3 ">
                <div className="col border-bottom border-1 border-dark">
                    <h1>Energy Logistics</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-md-6 order-2 ">
                                <div className="card-body h-100 services-text d-flex flex-column">
                                    <h2 className="card-title">Marine Fuels Sales</h2>
                                    <p className="card-text">
                                        One of the most experienced marine fuels brokerage and trading teams in the market spread
                                        across several key timezones, we understand what bunkers means to you and know how to add value
                                        to your business, 24/7.
                                    </p>

                                    <div className="mb-3 d-none d-lg-block fw-bold ">
                                        <div>Worldwide coverage</div>
                                        <div>Market knowlegde</div>
                                        <div>All products</div>
                                    </div>
                                    <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                        <Link to="/energy-logistics/services/marine-fuels-sales" className="text-dark">
                                            Learn more 
                                            <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 order-md-2 d-flex justify-content-center  align-items-center">
                                <StaticImage
                                    layout={layout}
                                    aspectRatio={aspectRatio}
                                    width={width}
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/service/bat.jpg"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="card mb-3  border-0">
                        <div className="row gx-3">
                            <div className="col-md-6 order-2 ">
                                <div className="card-body h-100 services-text d-flex flex-column">
                                    <h2 className="card-title">Physical Supply</h2>
                                    <p className="card-text">
                                        We operate time-chartered barges in ARA and the river Thames, UK. We guarantee premium product
                                        at competitive prices at all of our physical supply locations.
                                    </p>
                                    <div className="row align-items-center">
                                        <div className="mb-3 d-none d-lg-block fw-bold col-lg-3">
                                            <div className=""></div>
                                            <div>Safe</div>
                                            <div>Prompt</div>
                                            <div>Quality</div>
                                        </div>
                                        <div className="mb-3 col-12  col-lg-9 justify-content-center d-flex align-items-center">
                                            <a
                                                className="me-2 me-sm-3"
                                                href="https://www.numerco.com/Delta/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <StaticImage src="../../../images/service/carbonneutral.png" height={100} alt="" />
                                            </a>
                                            <span>Our marine fuel barges are sailing 100% carbon neutral.</span>
                                        </div>
                                    </div>

                                    <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                        <Link to="/energy-logistics/services/physical-supply" className="text-dark">
                                            Learn more 
                                            <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 d-flex justify-content-center  align-items-center">
                                <StaticImage
                                    layout={layout}
                                    aspectRatio={aspectRatio}
                                    width={width}
                                    alt="Barge"
                                    src="../../../images/service/tilldeymann.jpg"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-md-6 order-2 ">
                                <div className="card-body h-100 services-text d-flex flex-column">
                                    <h2 className="card-title">Retail Fuel Distribution</h2>
                                    <p className="card-text">
                                        Our Energy Logistic presence is rapidly growing in East Africa where we roll out our Retail
                                        Fuel distribution network under the DC Energy banner. To service our distribution, we also own
                                        two liquid bulk storage facilities in Uganda. We are a registered Oil Marketing Company (OMC)
                                        in Kenya, Uganda, Tanzania and Rwanda.
                                    </p>
                                    <div className="mb-3 d-none d-lg-block fw-bold">
                                        <div>Premium Motor Spirit (PMS)</div>
                                        <div>Automotive Gas Oil (AGO)</div>
                                        <div>Kerosene (IK)</div>
                                        <div>LPG</div>
                                    </div>
                                    <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                        <Link to="/energy-logistics/services/retail-fuel-distribution" className="text-dark">
                                            Learn more 
                                            <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 order-md-2 d-flex justify-content-center  align-items-center">
                                <StaticImage
                                    layout={layout}
                                    aspectRatio={aspectRatio}
                                    width={width}
                                    alt="Gas Station"
                                    src="../../../images/service/evara.jpg"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="card mb-3  border-0">
                        <div className="row gx-3">
                            <div className="col-md-6 order-2 ">
                                <div className="card-body h-100 services-text d-flex flex-column">
                                    <h2 className="card-title">Lubricants blending and distribution</h2>
                                    <p className="card-text">
                                        Delta Energies Nigeria has state of art lubricant blending plant in in heart of Lagos, Nigeria.
                                        We blend & distribute premium quality hi-performance Automotive, Industrial & Marine Lubricants
                                        in heart of West Africa which is the biggest lubricant market in Africa.
                                    </p>

                                    <div className="mb-3 d-none d-lg-block fw-bold ">
                                        <div>Automotive</div>
                                        <div>Industrial</div>
                                        <div>Marine</div>
                                    </div>
                                    <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                        <Link
                                            to="/energy-logistics/services/lubricants-blending-and-distribution"
                                            className="text-dark"
                                        >
                                            Learn more 
                                            <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 d-flex justify-content-center align-items-center">
                                <StaticImage
                                    layout={layout}
                                    aspectRatio={aspectRatio}
                                    width={width}
                                    alt="Hi Speed lubricant"
                                    src="../../../images/service/hispeed2.jpeg"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-md-6 order-2 ">
                                <div className="card-body h-100 services-text d-flex flex-column">
                                    <h2 className="card-title">Digital Solutions</h2>
                                    <p className="card-text">
                                        Delta Connect, our digital marine fuel procurement and management platform. Receive live quotes
                                        with quality statistics on your screen. Have all the benifts of digital age, but rest assured
                                        that our experienced people have your back.
                                    </p>

                                    <div className="mb-3 d-none d-lg-block fw-bold ">
                                        <div>Live</div>
                                        <div>Unbeatable price/quality</div>
                                        <div>Advanced analytics</div>
                                    </div>
                                    <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                        <Link to="/energy-logistics/services/digital-solutions" className="text-dark">
                                            Learn more 
                                            <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 order-md-2 d-flex justify-content-center  align-items-center">
                                <StaticImage
                                    layout={layout}
                                    aspectRatio={aspectRatio}
                                    width={width}
                                    alt="Delta Connect platform"
                                    src="../../../images/service/dashboard1.jpg"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="card mb-3  border-0">
                        <div className="row gx-3">
                            <div className="col-md-6 order-2 ">
                                <div className="card-body h-100 services-text d-flex flex-column">
                                    <h2 className="card-title">Credit & Risk Management</h2>
                                    <p className="card-text">
                                        In these turbulent times, we understand perfectly that our customers may want to have
                                        additional reassurance for price and supply risks for their business.
                                    </p>

                                    <div className="mb-3 d-none d-lg-block fw-bold ">
                                        <div>Credit lines</div>
                                        <div>Fixed rates</div>
                                        <div>Avoid surprises</div>
                                    </div>
                                    <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                                        <Link to="/energy-logistics/services/credit-and-risk-management" className="text-dark">
                                            Learn more 
                                            <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-1 d-flex justify-content-center align-items-center">
                                <StaticImage
                                    layout={layout}
                                    aspectRatio={aspectRatio}
                                    width={width}
                                    alt="Risk"
                                    src="../../../images/service/creditandrisk1.jpg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
