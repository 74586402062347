import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HeroBgImage from "../../components/energy-logistics/index/hero-image"
import Services from "../../components/energy-logistics/index/services"
import Products from "../../components/energy-logistics/index/products"
import LatestNews from "../../components/energy-logistics/index/latest-news"
import Ibia from "../../components/energy-logistics/index/ibia"

const IndexPage = ({ location }) => (
    <Layout location={location}>
        <Seo title="Home" />
        <HeroBgImage />
        <Services />
        <Ibia />
        <Products />
        <LatestNews />
    </Layout>
)

export default IndexPage
